<template>
  <div>
    <router-view></router-view>
  </div>
</template>

<script>
export default {
  name: "App",
};
</script>

<style>
* {
  margin: 0;
  padding: 0;
  color: #333;
}
li {
  list-style: none;
}
a {
  text-decoration: none;
}
#app {
  font-family: "Mukta", sans-serif;
}
</style>
